<app-card [hidHeader]="true" cardClass="card-datatable">
    <div class="table-responsive m-0">
      <table datatable [dtOptions]="dtOptions" class="table table-striped m-0">
        <thead class="thead-light">
          <tr>
              <th>Code</th>
              <th>Libélle</th>
              <th>Montant</th>
              <th>Payé</th>
              <th>Impayé</th>
              <th>Délai</th>
              <th>Etat</th>
              <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of fundsNotices">
            <td><span>{{item?.code}}</span></td>
            <td><span>{{item?.libelle}}</span></td>
            <td><span>{{item?.montant | number}} xof</span></td>
            <td><span>{{item?.paye | number}} xof</span></td>
            <td><span>{{item?.impaye | number}} xof</span></td>
            <td><span>{{item?.delai | number}}</span></td>
            <td><span>{{item?.etat}}</span></td>
            <td class="text-center">
              <button (click)="show(item)" type="button" class="btn btn-icon btn-secondary" ngbTooltip="Détails">
                <i class="fas fa-eye"></i>
              </button>
              <button (click)="delete(item)" type="button" class="btn btn-icon btn-danger ml-1" ngbTooltip="Supprimer">
                <i class="feather icon-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
    </table>
    </div>
  </app-card>