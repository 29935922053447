<div class="modal-header">
    <h5 class="modal-title">{{title}}</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="onClose()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<form autocomplete="off" [formGroup]="form">
    <div class="modal-body">
        <div class="col-md-12">
            <!-- FILTRE -->
            <div class="row">
                <span class="badge badge-warning my-2 f-14 width"> SELECTIONNER LA PERIODE </span>
            </div>
            <div class="form-group">
                <div class="row mb-2">
                    <div class="col-md-4">
                      <label for="house">Bien concerné <span class="asterix">*</span></label>
                      <select (change)="selectHouse($event.target.value)" formControlName="house" class="form-control" id="house">
                        <option value="null" selected>Selectionnez un bien</option>
                        <option *ngFor="let item of houses" [value]="item.uuid">{{item.nom}}</option>
                      </select>
                    </div>
                    <div class="col-md-3">
                      <label for="dateD">Date debut</label>
                      <input class="form-control" type="date" formControlName="dateD" id="dateD" />
                    </div>
                    <div class="col-md-3">
                      <label for="dateF">Date Fin</label>
                      <input class="form-control" type="date" formControlName="dateF" id="dateF" />
                    </div>
                    <div class="col-md-1 mt-4">
                      <button type="button" (click)="onLoadData()" class="btn btn-primary">
                        <i class="feather icon-filter"></i>
                      </button>
                    </div>
                    <div *ngIf="options.length > 0" class="col-md-3 mt-4">
                        <button  (click)="print()" type="button" class="btn btn-warning"
                          ngbTooltip="Cliquez ici pour Imprimer">
                          Imprimer<i class="feather icon-printer"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="f.owner.value">
                <span class="badge badge-warning my-2 f-14 width"> TRANSACTIONS</span>
            </div>
            <div class="row" *ngIf="f.owner.value">
                <div class="col-md-12" *ngIf="options.length > 0">
                    <div class="table-responsive" >
                      <table datatable [dtOptions]="dtOptions" class="table table-sm table-striped table-bordered nowrap table-hover">
                        <thead class="thead-light">
                        <tr>
                          <th>ID</th>
                          <th>Date</th>
                          <th>LIBELLE</th>
                          <th>DÉBIT</th>
                          <th>CRÉDIT</th>
                          <th>SOLDE</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let item of options; let i=index">
                          <td> #{{ i + 1 }} </td>
                          <td> {{ item?.date| date: "d MMMM y" : '' : 'fr-FR'  }} </td>
                          <td>
                            <p class="m-0 d-inline-block align-middle font-16">{{ item?.libelle }}</p>
                          </td>
                          <td class="align-middle">
                            <p class="m-0 d-inline-block align-middle font-16">{{ item?.debit |number}} {{global.device}}</p>
                          </td>
                          <td class="align-middle">
                            <p class="m-0 d-inline-block align-middle font-16"> {{ item?.credit |number}} {{global.device}}</p>
                          </td>
                          <td class="align-middle">
                            <p class="m-0 d-inline-block align-middle font-16"> {{ f.house.value ? (item.soldeNewParent |number) : (item?.soldeNew |number)}} {{global.device}}</p>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button (click)="onClose()" type="button" class="btn btn-secondary text-left" data-dismiss="modal">
                Fermer <i class="feather icon-x-circle"></i>
            </button>
        </div>
    </div>
</form>