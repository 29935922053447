
import { Owner } from '@model/owner';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { OwnerService } from '@service/owner/owner.service';
import { WalletService } from '@service/wallet/wallet.service';
import { EmitterService } from '@service/emitter/emitter.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UploaderService } from '@service/uploader/uploader.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from '@theme/utils/globals';
import { WithdrallService } from '@service/wallet/withdrawll.service';
import { HouseService } from '@service/house/house.service';

@Component({
  selector: 'app-owner-releve',
  templateUrl: './owner-releve.component.html',
  styleUrls: ['./owner-releve.component.scss']
})
export class OwnerReleveComponent implements OnInit {

  title: string = "";
  owner: Owner
  form: FormGroup;
  options: any[] = []
  dtOptions: any = {};
  house: any
  houses: any[] = []
  userSession = Globals.user
  global = { country: Globals.country, device: Globals.device }

  constructor(
    public modal: NgbActiveModal,
    public toastr: ToastrService,
    private modalService: NgbModal,
    private formBuild: FormBuilder,
    private emitter: EmitterService,
    public houseService: HouseService,
    private ownerService: OwnerService,
    public modalActive: NgbActiveModal,
    public walletService: WalletService,
    public uploadService: UploaderService,
    public withdrawllService: WithdrallService,
  ) { 
    this.owner = this.ownerService.getOwner()
    this.title = "Tirer le relever de compte du propriétaire ";
    this.newForm();
    if (this.owner) {
      this.f.owner.setValue(this.owner.uuid)
    }
    this.loadHouses();
  }

  ngOnInit(): void {
    this.dtOptions = Globals.dataTable;
  }
  newForm() {
    this.form = this.formBuild.group({
      uuid: [null],
      id: [null],
      house: [null],
      owner: [null, [Validators.required]],
      dateD: [null, [Validators.required]],
      dateF: [null, [Validators.required]],
    });
  }
  loadHouses() {
    this.houses = [];
    this.houseService.getList(this.f.owner.value).subscribe((res: any) => {
      this.houses = res;
      return this.houses;
    }, error => {
    });
  }
  selectHouse(value) {
    this.house = this.houses.find(item => {
      if (item.uuid === value) {
        this.f.house.setValue(item.uuid);
        return item;
      }
    });
    this.f.house.setValue(value);
  }
  onLoadData(){
    this.walletService.getItems(this.form.value).subscribe(res => {
      if(res){
        this.options = res.data
        return this.options
      }
    });
  }
  print() {
    this.withdrawllService.getReleve(null, this.userSession?.agencyKey, this.userSession?.uuid, this.f.owner.value, this.f.dateD.value, this.f.dateF.value);
  }
  onClose(){
    this.form.reset();
    this.modal.close('ferme');
  }
  toast(msg, title, type) {
    if (type == 'info') {
      this.toastr.info(msg, title);
    } else if (type == 'success') {
      this.toastr.success(msg, title);
    } else if (type == 'warning') {
      this.toastr.warning(msg, title);
    } else if (type == 'error') {
      this.toastr.error(msg, title);
    }
  }
  get f() { return this.form.controls; }
}
