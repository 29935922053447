<div class="modal-header">
  <h5 class="modal-title">{{title}}</h5>
  <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="modal.close('ferme')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="modal-body">
    <div class="col-md-12">
      <div class="row">
        <span class="badge badge-warning my-2 f-14 width"> DETAILS DU MOT DE PASSE</span>
      </div>
      <div class="form-group">
        <div class="row mb-2">
          <div class="col-md-6">
            <label for="type">Type <span class="asterix">*</span></label>
            <select formControlName="type" class="form-control" id="type"
              [ngClass]="{
              'is-invalid': submit && f.type.errors,
              'is-valid': submit && f.type.valid }">
              <option *ngFor="let item of types" [ngValue]="item.value">{{item.label}}</option>
            </select>
            <div class="invalid-feedback" *ngIf="submit && f.type.errors">
              <div *ngIf="f.type.errors.required">{{required.novide}}</div>
            </div>
          </div>
          <div class="col-md-6">
            <label for="user">
              <span *ngIf="f.type.value === 'CLIENT'">Client </span>
              <span *ngIf="f.type.value === 'LOCATAIRE'">Locataire </span>
              <span *ngIf="f.type.value === 'PROPRIETAIRE'">Propriétaire </span>
              <span class="asterix">*</span>
            </label>
            <app-entity-finder [class]="entity.class" [groups]="[entity.groups]" [required]="true"
              (uuid)="setUserUuid($event)">
            </app-entity-finder>
            <div class="invalid-feedback" *ngIf="submit && f.user.errors">
            <div *ngIf="f.user.errors.required">{{required.novide}}</div>
            </div>
          </div>
          <div class="col-md-6">
            <label for="username">Nom d'utilisateur <span class="asterix">*</span></label>
            <input type="text" formControlName="username" class="form-control" id="username"
              [ngClass]="{
                'is-invalid': submit && f.username.errors,
                'is-valid': submit && f.username.valid
              }" placeholder="Nom d'utilisateur">
            <div class="invalid-feedback" *ngIf="submit && f.username.errors">
              <div *ngIf="f.username.errors.required">{{required.novide}}</div>
            </div>
          </div>
          <div class="col-md-6">
            <label for="user">Mot de passe <span class="asterix">*</span></label>
            <div class="input-group mb-3">
              <input type="text" formControlName="password" class="form-control" id="password"
                [ngClass]="{
                  'is-invalid': submit && f.password.errors,
                  'is-valid': submit && f.password.valid
                }" placeholder="Mot de passe">
              <div class="invalid-feedback" *ngIf="submit && f.password.errors">
                <div *ngIf="f.password.errors.required">{{required.novide}}</div>
              </div>
              <div class="input-group-append">
                <button (click)="setPassword()" type="button" class="btn btn-warning m-1" ngbTooltip="Genére mot de passe">
                  <i class="fas fa-redo"></i>
                </button>
                <!-- <span class="input-group-text"><span class="fas fa-copy"></span></span> -->
              </div>
            </div>
          </div>
          <div class="col-md-1 mt-4">
          </div>
      </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="modal.close('ferme')" type="button" class="btn btn-secondary text-left" data-dismiss="modal">
      Fermer <i class="feather icon-x-circle"></i>
    </button>
    <button (click)="form.reset()" type="button" class="btn btn-warning">
      Vider <i class="fas fa-broom"></i>
    </button>
    <button [disabled]="form.invalid" type="submit" class="btn btn-primary">
      Enregistrer <i class="feather icon-save"></i>
    </button>
  </div>
</form>
