<div>
  <label class="label col-md-3" [style]="{'background': background}">
    <input type="file" style="display: none;" (change)="uploadImage($event.target.files)">
    <span *ngIf="!background && !isUploading">
      <i class="fas fa-user" style="font-size: 30px;"></i>
    </span>
    <div *ngIf="success" style="position: absolute;width:100%;height:100%;
      left:0;top:0;background: rgba(81,200,121,0.5);
      display: flex; align-items: center;justify-content: center">
    </div>
    <div *ngIf="error" style="position: absolute;width:100%;height:100%;left:0;top:0;background: rgba(200,0,0,0.5)"></div>
    <span style="position: relative;z-index: 4" *ngIf="!background && !isUploading">{{message}}</span>
    <span style="position: relative;z-index: 4;color:white" *ngIf="error">{{message}}</span>
  </label>
  <div *ngIf="background" class="delete">
    <i class="fas fa-trash" style="font-size: 30px;color:rgb(240, 16, 16);" (click)="delete()"></i>
  </div>
</div>
