<div class="card rounded border-0 p-3 mb-3">
  <form [formGroup]="form" (ngSubmit)="filters()">
    <div class="form-group">
      <div class="row">
        <div *ngIf="type" class="mb-2 col-md">
          <label for="type">Type</label>
          <select (change)="types()" formControlName="type" class="form-control" id="type">
            <option *ngFor="let item of typeRow" [value]="item.value">{{
              item.label
              }}</option>
          </select>
        </div>

        <ng-container *ngFor="let input of inputs">
          <div *ngIf="input.top && input.visible &&
            (input.groups.includes(f.type.value) ||
            input.groups.includes('ALL'))" [ngClass]="{
              'mb-2':
                input.top &&
                (input.groups.includes(f.type.value) ||
                input.groups.includes('ALL'))
            }" [className]="input.column ? 'col-md-' + input.column : 'col-md'">
            <div [ngSwitch]="input.controlType">
              <div *ngSwitchCase="'textbox'">
                <label [attr.for]="input.key">{{ input.label }}</label>
                <input class="form-control" [formControlName]="input.key" [id]="input.key" [type]="input.type"
                  [placeholder]="input.label" />
              </div>

              <div *ngSwitchCase="'dropdown'">
                <label [attr.for]="input.key">{{ input.label }}</label>
                <ng-select [placeholder]="input.label" [formControlName]="input.key" [id]="input.key"
                  [(ngModel)]="input.value">
                  <ng-option *ngFor="let opt of input.options" [value]="opt.key" [attr.title]="opt.value">{{ opt.value
                    }}</ng-option>
                </ng-select>
              </div>

              <div *ngSwitchCase="'finder'">
                <label [attr.for]="input.key">{{ input.label }}</label>
                <app-searchable-select [controlName]="input.key" [namespace]="input.finderParams.namespace"
                  [groups]="input.finderParams.groups" [class]="input.finderParams.class"
                  [bindLabel]="input.finderParams.bindLabel" [bindValue]="input.finderParams.bindValue"
                  [multiple]="input.multiple">
                </app-searchable-select>
              </div>

              <div *ngSwitchCase="'checkbox'">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" [formControlName]="input.key" [id]="input.key" />
                  <label class="form-check-label" [attr.for]="input.key">{{
                    input.label
                    }}</label>
                </div>
              </div>

              <div *ngSwitchCase="'radio'">
                <div class="form-check form-check-inline" *ngFor="let opt of input.options">
                  <input class="form-check-input" type="radio" [formControlName]="input.key" [id]="input.key"
                    [value]="opt.value" />
                  <label class="form-check-label" [attr.for]="opt.key">
                    {{ opt.value }}
                  </label>
                </div>
              </div>

              <div *ngSwitchCase="'textarea'">
                <label [attr.for]="input.key">{{ input.label }}</label>
                <textarea class="form-control" [formControlName]="input.key" [id]="input.key" rows="5"></textarea>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <hr [ngbCollapse]="advance" />

      <div class="row" id="filterForm" [ngbCollapse]="advance">
        <ng-container *ngFor="let input of inputs">
          <div *ngIf="!input.top && input.visible && (input.groups.includes(f.type.value) || input.groups.includes('ALL'))"
            style="margin-bottom: 10px;" [ngClass]=" { 'mb-2' : !input.top && (input.groups.includes(f.type.value) || input.groups.includes('ALL'))
            }" [className]="input.column ? 'col-md-' + input.column : 'col-md'">
            <div [ngSwitch]="input.controlType">
              <div *ngSwitchCase="'textbox'">
                <label [attr.for]="input.key">{{ input.label }}</label>
                <input class="form-control" [formControlName]="input.key" [id]="input.key" [type]="input.type"
                  [placeholder]="input.label" />
              </div>
              <div *ngSwitchCase="'dropdown'">
                <label [attr.for]="input.key">{{ input.label }}</label>
                <ng-select [placeholder]="input.label" [formControlName]="input.key" [id]="input.key">
                  <ng-option *ngFor="let opt of input.options" [value]="opt.key" [attr.title]="opt.value">{{ opt.value
                    }}</ng-option>
                </ng-select>
              </div>
              <div *ngSwitchCase="'checkbox'">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" [formControlName]="input.key" [id]="input.key" />
                  <label class="form-check-label" [attr.for]="input.key">{{
                    input.label
                    }}</label>
                </div>
              </div>
              <div *ngSwitchCase="'radio'">
                <div class="form-check form-check-inline" *ngFor="let opt of input.options">
                  <input class="form-check-input" type="radio" [formControlName]="input.key" [id]="input.key"
                    [value]="opt.value" />
                  <label class="form-check-label" [attr.for]="opt.key">
                    {{ opt.value }}
                  </label>
                </div>
              </div>
              <div *ngSwitchCase="'textarea'">
                <label [attr.for]="input.key">{{ input.label }}</label>
                <textarea class="form-control" [formControlName]="input.key" [id]="input.key" rows="5"></textarea>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <hr />
    <div class="d-flex flex-row-reverse">

      <a class="btn btn-primary text-white ml-2" (click)="advance = !advance" ngbTooltip="Filtre avancé"
        [attr.aria-expanded]="!advance" aria-controls="filterForm">
        <i class="fas fa-expand-arrows-alt"></i>
      </a>
      <button type="submit" class="btn btn-primary ml-2">
        Filtrer <i class="pe-7s-filter"></i>
      </button>
      <button type="button" class="btn btn-secondary ml-2" (click)="preview()">
        Aperçu <i class="fas fa-eye"></i>
      </button>
    </div>
  </form>
</div>
