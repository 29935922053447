
<div class="row justify-content-center">

    <!-- FILTRE DE RECHERCHE -->
    <div class="width btn-filter-syndic">
        <app-filter class="width"
            [name]="name"
            [nameTitle]="nameTitle"
            [bien]="bien"
            [bienTitle]="bienTitle"
            [user]="true"
            [userTitle]="userTitle"
            [etat]="etat"
            [etatRow]="etatRow"
            [etatTitle]="etatTitle" [categorie]="categorie" [categorieRow]="categorieRow" [categorieTitle]="categorieTitle" [dateD]="dateD" [dateF]="dateF" [create]="true" [ordre]="true" [code]="false" [count]="true" [type]="true" [typeRow]="typeRow" (typeEvent)="onChangeLoad($event)" (filterEvent)="onFilter($event)">
        </app-filter>
    </div>

    <!-- BOUTONS DE GESTION -->
    <div class="col-sm-12 mb-4 btn-all-syndic">
        <div class="first">
            <div class="second" style="flex-wrap: wrap;">
                <div class="btn-syndic">
                  <ng-template [ngxPermissionsOnly]="['TRUSTEE:NEW']">
                    <button (click)="addSyndic()" class="btn btn-primary" type="button" ngbTooltip="Cliquez ici pour ajouter un nouveau syndic">
                        Créer un syndic <i class="feather icon-box"></i>
                    </button>
                  </ng-template>
                </div>


                <div class="btn-owner">
                  <div *ngxPermissionsOnly="'OWNER:NEW'" class="btn-group m-1" ngbDropdown
                    ngbTooltip="Cliquez ici pour ajouter un propriétaire">
                    <button class="btn btn-info" ngbDropdownToggle type="button">
                      Co-propriétaire <i class="feather icon-user"></i>
                    </button>
                    <div ngbDropdownMenu>
                      <a class="dropdown-item" (click)="addHouseCo('PARTICULIER')">PARTICULIER</a>
                      <a class="dropdown-item" (click)="addHouseCo('ENTREPRISE')">ENTREPRISE</a>
                    </div>
                  </div>
                </div>
                <div class="btn-infrastructure">
                  <ng-template [ngxPermissionsOnly]="['INFRASTRUCTURE:TRUSTEE:NEW']">
                    <button (click)="addInfrastructure()" type="button" class="btn btn-warning" ngbTooltip="Cliquez ici pour ajouter une nouvelle infrastructure">
                        Créer une infrastructure <i class="fas fa-city"></i>
                    </button>
                  </ng-template>
                </div>
                <div class="btn-house">
                  <button  (click)="addCopropriete()" type="button" class="btn btn-dark m-1"
                  ngbTooltip="Cliquez ici pour ajouter un lot">
                  Créer un lot <i class="feather icon-home"></i>
                  </button>
                </div>
                <div class="btn-budget">
                  <button *ngxPermissionsOnly="['TYPELOAD:NEW']" class="btn btn-info m-1" (click)="addTypeLoad()"
                    type="button" ngbTooltip="Cliquez ici pour créer un type de charge">
                    Créer un type de charge <i class="fas fa-cash-register"></i>
                  </button>
                </div>
                <div class="btn-building">
                  <button *ngxPermissionsOnly="['BUDGET:NEW']" (click)="addBudget()" type="button" class="btn btn-warning m-1"
                    ngbTooltip="Cliquez ici pour créer budget">
                    Créer un budget <i class="fas fa-money-bill"></i>
                  </button>
                </div>

                <div class="btn-budget">
                  <button  class="btn btn-dark m-1" (click)="addMandat()"
                    type="button" ngbTooltip="Cliquez ici pour créer un mandat">
                    Créer un mandat <i class="feather icon-file-text "></i>
                  </button>
                </div>

            </div>
        </div>
        <div class="btn-group float-right m-1 btn-outil-syndic" ngbDropdown>
            <button class="btn btn-warning" ngbDropdownToggle type="button">
                Outils <i class="fas fa-tools"></i>
            </button>
            <ng-template [ngxPermissionsOnly]="['TRUSTEE:LIST']">
              <div class="dropdown-menu-right" ngbDropdownMenu>
                  <a class="dropdown-item" ngbTooltip="Cliquez ici pour imprimer le listing" (click)="onPrinter()">
                      Imprimer <i class="feather icon-printer"></i>
                  </a>
                  <a class="dropdown-item" ngbTooltip="Cliquez ici pour exporter le listing" (click)="onExport()">
                      Exporter <i class="fa fa-file-excel"></i>
                  </a>
              </div>
            </ng-template>
        </div>
    </div>

    <!-- LISTE DES SYNDICS -->
    <div class="width list-syndic">
        <ng-template [ngxPermissionsOnly]="['TRUSTEE:LIST']">
          <div *ngIf="type === 'SYNDIC'" class="col-md-12">
              <span class="badge badge-primary mb-4 f-14 width">
                  LISTE DES SYNDICS
              </span>
              <app-no-data [title]="'Aucun syndic trouvé'" *ngIf="syndics && syndics.length === 0"></app-no-data>
                <div>
                      <div class="row" *ngIf="syndics && syndics.length > 0">
                          <div class="col-md-4" *ngFor="let item of syndics">
                                <app-card [hidHeader]="true" cardClass="user-card user-card-1 mt-4" blockClass="pt-0" >
                                        <div (click)="showSyndic(item)">
                                            <div class="user-about-block text-center">
                                                <div class="row align-items-end">
                                                    <div class="row align-items-end">
                                                        <div class="col text-left pb-3"></div>
                                                        <div class="col text-right pb-3">
                                                        <span class="badge badge-light-{{item?.mandate === true ? 'warning' : item?.mandate === false ? 'danger' : 'secondary'}} ml-1"><small>{{item?.mandate === true ? 'MANDAT EN COURS' :  item?.mandate === false ? 'SANS MANDAT' : ''}}</small> </span>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <img class="img-radius img-fluid wid-80 hei-80"
                                                        [src]="item.photoSrc ? publicUrl+'/'+item.photoSrc : 'assets/images/syndic.jpeg'"
                                                        onerror="this.onerror=null; this.src='assets/images/syndic.jpeg'" />
                                                    </div>
                                                    <div class="col text-right pb-3">
                                                        <span>{{ item?.etat }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="text-center">
                                                <h4 class="mb-1 mt-3">{{item?.nom}}</h4>
                                                <p class="mb-3 text-muted">
                                                    <b><i class="fas fa-qrcode"></i> {{ item.code }}</b>
                                                </p>
                                            </div>
                                            <div class="text-center mt-2">
                                                <span class="mb-1">
                                                    {{ item?.pays?.nom }}, {{ item?.ville }}
                                                </span><br/>
                                            </div>
                                            <div class="text-center mt-2">
                                                <span class="mb-1">
                                                    <b>CRÉDIT:</b> {{ item?.credit|number }} XOF, &nbsp;&nbsp;&nbsp; <b>DÉBIT:</b> {{ item?.debit|number }} XOF
                                                </span><br/>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="text-right">
                                            <button (click)="showSyndic(item)" type="button" class="btn btn-icon btn-secondary" ngbTooltip="Détails">
                                                <i class="fas fa-eye"></i>
                                            </button>
                                            <button (click)="editSyndic(item)" type="button"class="btn btn-icon btn-primary ml-1" ngbTooltip="Modifier">
                                                <i class="feather icon-edit-2"></i>
                                            </button>
                                            <button (click)="printSyndic(item)" type="button"class="btn btn-icon btn-warning ml-1" ngbTooltip="Imprimer">
                                                <i class="feather icon-printer"></i>
                                            </button>
                                            <button (click)="deleteSyndic(item)" type="button" class="btn btn-icon btn-danger ml-1" ngbTooltip="Supprimer">
                                                <i class="feather icon-trash"></i>
                                            </button>
                                        </div>
                                </app-card>
                          </div>
                      </div>
                </div>
          </div>
        </ng-template>
    </div>
    <ng-template ngxPermissionsExcept="TRUSTEE:LIST" [ngxPermissionsOnly]="['TRUSTEE:LIST']">
      <div class="col-md-12">
        <app-no-droit *ngIf="type === 'SYNDIC'" [title]="'liste des syndics'"></app-no-droit>
      </div>
    </ng-template>
    <!-- LISTE DES SYNDICS -->

  <!-- LISTE DES PROPRIETAIRES -->
  <div class="width list-owner">
    <ng-template ngxPermissionsOnly="OWNER:LIST">
      <div class="col-md-12" *ngIf="type === 'CO-PROPRIETAIRE'">
        <span class="badge badge-primary mb-4 f-14 width">
          LISTE DES CO-PROPRIETAIRES
        </span>
        <app-no-data [title]="'Aucun co-propriétaire trouvé'" *ngIf="coowners && coowners.length === 0"></app-no-data>
        <div *ngIf="!view">
          <div class="row" *ngIf="coowners && coowners.length > 0">
            <div class="col-md-6" *ngFor="let item of coowners">
              <app-card [hidHeader]="true" cardClass="user-card user-card-1 mt-4" blockClass="pt-0">
                <div class="user-about-block text-center">
                  <div class="row align-items-end">
                    <div class="col text-left pb-3">
                      <span
                        class="badge badge-{{ item.type === 'ENTREPRISE' ? 'info': 'secondary' }}">{{item?.type}}</span>
                    </div>
                    <div class="col">
                      <img *ngIf="item?.type === 'PARTICULIER' && item?.sexe === 'Masculin'"
                        class="img-radius img-fluid wid-80 hei-80"
                        [src]="item.photoSrc ? publicUrl+'/'+item.photoSrc : 'assets/images/avatar-default.png'"
                        onerror="this.onerror=null; this.src='assets/images/avatar-default.png'" />
                      <img *ngIf="item?.type === 'PARTICULIER' && item?.sexe === 'Féminin'"
                        class="img-radius img-fluid wid-80 hei-80"
                        [src]="item.photoSrc ? publicUrl+'/'+item.photoSrc : 'assets/images/avatar-mlle.jpeg'"
                        onerror="this.onerror=null; this.src='assets/images/avatar-mlle.jpeg'" />
                      <img *ngIf="item?.type === 'ENTREPRISE' && item?.photoSrc"
                        class="img-radius img-fluid wid-80 hei-80"
                        [src]="item.photoSrc ? publicUrl+'/'+item.photoSrc : 'assets/images/avatar-default.png'"
                        onerror="this.onerror=null; this.src='assets/images/avatar-default.png'" />
                      <i *ngIf="item?.type === 'ENTREPRISE' && !item?.photoSrc" class="fas fa-building fa-5x"></i>
                    </div>
                    <div class="col text-right pb-3">
                    </div>
                  </div>
                </div>
                <div class="text-center">
                  <a (click)="showOwner(item)" href="javascript:">
                    <h4 class="mb-1 mt-3">{{item?.nom}}</h4>
                    <p class="mb-3 text-muted">
                      <b><i class="fas fa-qrcode"></i> {{ item.code }}</b>
                      <b class="m-4"><i class="feather icon-phone"></i> {{item?.telephone}}</b>
                    </p>
                    <p class="mb-1">
                      <b> {{ item?.type === 'ENTREPRISE' ? 'Siège social' : 'Domicile' }} : </b>
                      {{ item?.type === 'ENTREPRISE' ? item?.siegeSocial : item?.domicile }}
                    </p>
                    <p class="mb-1">
                      <b class="m-4 text-danger font-weight-bold">BRUT À REVERSER : {{item?.impaye| number }} {{global.device}}</b> -
                      <b class="m-4 text-warning font-weight-bold">DÉJÀ PERÇU : {{item?.reverse| number }} {{global.device}}</b>
                    </p>
                  </a>
                </div>
                <div class="text-right">
                  <button *ngxPermissionsOnly="'OWNER:SHOW'" (click)="showOwnerCo(item)" type="button"
                    class="btn btn-icon btn-secondary" ngbTooltip="Détails">
                    <i class="fas fa-eye"></i>
                  </button>
                  <button *ngxPermissionsOnly="'OWNER:EDIT'" (click)="editOwnerCo(item)" type="button"
                    class="btn btn-icon btn-primary ml-1" ngbTooltip="Modifier">
                    <i class="feather icon-edit-2"></i>
                  </button>
                  <button *ngxPermissionsOnly="'OWNER:PRINTER:SHOW'" (click)="printerOwner(item)" type="button"
                    class="btn btn-icon btn-warning ml-1" ngbTooltip="Imprimer">
                    <i class="feather icon-printer"></i>
                  </button>
                  <ng-template ngxPermissionsOnly="OWNER:DELETE">
                    <button *ngIf="item?.isDelete" (click)="delete(item)" type="button"
                      class="btn btn-icon btn-danger ml-1" ngbTooltip="Supprimer">
                      <i class="feather icon-trash"></i>
                    </button>
                  </ng-template>
                </div>
              </app-card>
            </div>
          </div>
        </div>
        <div *ngIf="view">
          <div class="table-responsive" *ngIf="coowners && coowners.length > 0">
            <table datatable [dtOptions]="dtOptions" class="table table-sm table-striped table-bordered nowrap table-hover">
              <thead class="thead-light">
                <tr>
                  <th>Référence</th>
                  <th>Nom</th>
                  <th>Type</th>
                  <th>Etat</th>
                  <th>À reverser</th>
                  <th>Déjà perçu</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of coowners">
                  <td>{{item?.code}}</td>
                  <td class="align-middle">
                    <img *ngIf="item?.type === 'PARTICULIER' && item?.sexe === 'Masculin'" class="img-radius img-fluid"
                      [src]="item.photoSrc ? publicUrl+'/'+item.photoSrc : 'assets/images/avatar-default.png'"
                      onerror="this.onerror=null; this.src='assets/images/avatar-default.png'" width="20%" />
                    <img *ngIf="item?.type === 'PARTICULIER' && item?.sexe === 'Féminin'" class="img-radius img-fluid"
                      [src]="item.photoSrc ? publicUrl+'/'+item.photoSrc : 'assets/images/avatar-mlle.jpeg'"
                      onerror="this.onerror=null; this.src='assets/images/avatar-mlle.jpeg'" width="20%" />
                    <img *ngIf="item?.type === 'ENTREPRISE' && item?.photoSrc" class="img-radius img-fluid"
                      [src]="item.photoSrc ? publicUrl+'/'+item.photoSrc : 'assets/images/avatar-default.png'"
                      onerror="this.onerror=null; this.src='assets/images/avatar-default.png'" width="20%" />
                    <i *ngIf="item?.type === 'ENTREPRISE' && !item?.photoSrc" class="fas fa-building fa-3x"></i>
                    <p class="m-2 d-inline-block align-middle font-16">
                      <span class="text-primary">{{ item?.nom }}</span> <br />
                      Email : {{ item?.email ? item?.email : 'N/A' }} <br />
                      Contact : {{ item?.telephone ? item?.telephone : 'N/A' }}
                    </p>
                  </td>
                  <td>
                    <span class="badge badge-{{ item.type === 'ENTREPRISE' ? 'info': 'secondary' }}">{{item?.type}}</span>
                  </td>
                  <td>
                    <span class="badge badge-{{ item?.etat === 'INACTIF' ? 'danger': (item?.etat === 'AUCUN MANDAT' ? 'warning' : 'success') }}">
                      {{ item?.etat }}
                    </span>
                  </td>
                  <td class="align-middle">
                    <span class="text-danger">{{item?.impaye|number}} {{global.device}}</span>
                  </td>
                  <td class="align-middle">
                    <p class="m-0 d-inline-block align-middle font-16">
                      <span class="text-warning">{{item?.reverse|number}} {{global.device}}</span>
                    </p>
                  </td>
                  <td class="align-middle">{{item?.createdAt| date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}</td>
                  <td class="table-action">
                    <button *ngxPermissionsOnly="'OWNER:SHOW'" (click)="showOwner(item)" type="button" class="btn btn-icon btn-secondary"
                      ngbTooltip="Détails">
                      <i class="fas fa-eye"></i>
                    </button>
                    <button *ngxPermissionsOnly="'OWNER:EDIT'" (click)="editOwner(item)" type="button" class="btn btn-icon btn-primary ml-1"
                      ngbTooltip="Modifier">
                      <i class="feather icon-edit-2"></i>
                    </button>
                    <button *ngxPermissionsOnly="'OWNER:PRINTER:SHOW'" (click)="printerOwner(item)" type="button" class="btn btn-icon btn-warning ml-1"
                      ngbTooltip="Imprimer">
                      <i class="feather icon-printer"></i>
                    </button>
                    <ng-template ngxPermissionsOnly="OWNER:DELETE">
                      <button *ngIf="item?.etat === 'DISPONIBLE'" type="button" (click)="deleteOwner(item)" class="btn btn-icon btn-danger ml-1" ngbTooltip="Supprimer">
                        <i class="feather icon-trash"></i>
                      </button>
                    </ng-template>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="4" class="font-weight-bold font-size-40 text-right">TOTAL</td>
                  <td class="font-weight-bold font-size-40 text-warning"> {{totalImpaye | number}} {{global.device}}</td>
                  <td class="font-weight-bold font-size-40 text-success"> {{totalReverse | number}} {{global.device}}</td>
                  <td></td>
                  <td></td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
  <ng-template ngxPermissionsExcept="OWNER:LIST" [ngxPermissionsOnly]="['HOUSE:LIST', 'RENTAL:LIST', 'MANDATE:LIST', 'PAYMENT:REPAYMENT:LIST',
    'REPAYMENT:LIST', 'RENEW:MANDATE:LIST']">
    <div class="col-md-12">
      <app-no-droit *ngIf="type === 'CO-PROPRIETAIRE'" [title]="'liste co-propriétaire'"></app-no-droit>
    </div>
  </ng-template>

  <!-- LISTE DES PROPRIETAIRES -->
  <div class="width list-owner">
    <ng-template ngxPermissionsOnly="OWNER:LIST">
      <div class="col-md-12" *ngIf="type === 'PROPRIETAIRE'">
        <span class="badge badge-primary mb-4 f-14 width">
          LISTE DES PROPRIETAIRES
        </span>
        <app-no-data [title]="'Aucun propriétaire trouvé'" *ngIf="owners && owners.length === 0"></app-no-data>
        <div *ngIf="!view">
          <div class="row" *ngIf="owners && owners.length > 0">
            <div class="col-md-6" *ngFor="let item of owners">
              <app-card [hidHeader]="true" cardClass="user-card user-card-1 mt-4" blockClass="pt-0">
                <div class="user-about-block text-center">
                  <div class="row align-items-end">
                    <div class="col text-left pb-3">
                      <span
                        class="badge badge-{{ item.type === 'ENTREPRISE' ? 'info': 'secondary' }}">{{item?.type}}</span>
                    </div>
                    <div class="col">
                      <img *ngIf="item?.type === 'PARTICULIER' && item?.sexe === 'Masculin'"
                        class="img-radius img-fluid wid-80 hei-80"
                        [src]="item.photoSrc ? publicUrl+'/'+item.photoSrc : 'assets/images/avatar-default.png'"
                        onerror="this.onerror=null; this.src='assets/images/avatar-default.png'" />
                      <img *ngIf="item?.type === 'PARTICULIER' && item?.sexe === 'Féminin'"
                        class="img-radius img-fluid wid-80 hei-80"
                        [src]="item.photoSrc ? publicUrl+'/'+item.photoSrc : 'assets/images/avatar-mlle.jpeg'"
                        onerror="this.onerror=null; this.src='assets/images/avatar-mlle.jpeg'" />
                      <img *ngIf="item?.type === 'ENTREPRISE' && item?.photoSrc"
                        class="img-radius img-fluid wid-80 hei-80"
                        [src]="item.photoSrc ? publicUrl+'/'+item.photoSrc : 'assets/images/avatar-default.png'"
                        onerror="this.onerror=null; this.src='assets/images/avatar-default.png'" />
                      <i *ngIf="item?.type === 'ENTREPRISE' && !item?.photoSrc" class="fas fa-building fa-5x"></i>
                    </div>
                    <div class="col text-right pb-3">
                      <span class="badge badge-{{ item?.etat === 'INACTIF' ? 'danger': (item?.etat === 'AUCUN MANDAT' ? 'warning' : 'success') }}">
                        {{ item?.etat }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="text-center">
                  <a (click)="showOwner(item)" href="javascript:">
                    <h4 class="mb-1 mt-3">{{item?.nom}}</h4>
                    <p class="mb-3 text-muted">
                      <b><i class="fas fa-qrcode"></i> {{ item.code }}</b>
                      <b class="m-4"><i class="feather icon-phone"></i> {{item?.telephone}}</b>
                    </p>
                    <p class="mb-1">
                      <b> {{ item?.type === 'ENTREPRISE' ? 'Siège social' : 'Domicile' }} : </b>
                      {{ item?.type === 'ENTREPRISE' ? item?.siegeSocial : item?.domicile }}
                    </p>
                    <p class="mb-1">
                      <b class="m-4 text-danger font-weight-bold">BRUT À REVERSER : {{item?.impaye| number }} {{global.device}}</b> -
                      <b class="m-4 text-warning font-weight-bold">DÉJÀ PERÇU : {{item?.reverse| number }} {{global.device}}</b>
                    </p>
                  </a>
                </div>
                <div class="text-right">
                  <button *ngxPermissionsOnly="'OWNER:SHOW'" (click)="showOwner(item)" type="button"
                    class="btn btn-icon btn-secondary" ngbTooltip="Détails">
                    <i class="fas fa-eye"></i>
                  </button>
                  <button *ngxPermissionsOnly="'OWNER:EDIT'" (click)="editOwner(item)" type="button"
                    class="btn btn-icon btn-primary ml-1" ngbTooltip="Modifier">
                    <i class="feather icon-edit-2"></i>
                  </button>
                  <button *ngxPermissionsOnly="'OWNER:PRINTER:SHOW'" (click)="printerOwner(item)" type="button"
                    class="btn btn-icon btn-warning ml-1" ngbTooltip="Imprimer">
                    <i class="feather icon-printer"></i>
                  </button>
                  <ng-template ngxPermissionsOnly="OWNER:DELETE">
                    <button *ngIf="item?.isDelete" (click)="delete(item)" type="button"
                      class="btn btn-icon btn-danger ml-1" ngbTooltip="Supprimer">
                      <i class="feather icon-trash"></i>
                    </button>
                  </ng-template>
                </div>
              </app-card>
            </div>
          </div>
        </div>
        <div *ngIf="view">
          <div class="table-responsive" *ngIf="owners && owners.length > 0">
            <table datatable [dtOptions]="dtOptions" class="table table-sm table-striped table-bordered nowrap table-hover">
              <thead class="thead-light">
                <tr>
                  <th>Référence</th>
                  <th>Nom</th>
                  <th>Type</th>
                  <th>Etat</th>
                  <th>À reverser</th>
                  <th>Déjà perçu</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of owners">
                  <td>{{item?.code}}</td>
                  <td class="align-middle">
                    <img *ngIf="item?.type === 'PARTICULIER' && item?.sexe === 'Masculin'" class="img-radius img-fluid"
                      [src]="item.photoSrc ? publicUrl+'/'+item.photoSrc : 'assets/images/avatar-default.png'"
                      onerror="this.onerror=null; this.src='assets/images/avatar-default.png'" width="20%" />
                    <img *ngIf="item?.type === 'PARTICULIER' && item?.sexe === 'Féminin'" class="img-radius img-fluid"
                      [src]="item.photoSrc ? publicUrl+'/'+item.photoSrc : 'assets/images/avatar-mlle.jpeg'"
                      onerror="this.onerror=null; this.src='assets/images/avatar-mlle.jpeg'" width="20%" />
                    <img *ngIf="item?.type === 'ENTREPRISE' && item?.photoSrc" class="img-radius img-fluid"
                      [src]="item.photoSrc ? publicUrl+'/'+item.photoSrc : 'assets/images/avatar-default.png'"
                      onerror="this.onerror=null; this.src='assets/images/avatar-default.png'" width="20%" />
                    <i *ngIf="item?.type === 'ENTREPRISE' && !item?.photoSrc" class="fas fa-building fa-3x"></i>
                    <p class="m-2 d-inline-block align-middle font-16">
                      <span class="text-primary">{{ item?.nom }}</span> <br />
                      Email : {{ item?.email ? item?.email : 'N/A' }} <br />
                      Contact : {{ item?.telephone ? item?.telephone : 'N/A' }}
                    </p>
                  </td>
                  <td>
                    <span class="badge badge-{{ item.type === 'ENTREPRISE' ? 'info': 'secondary' }}">{{item?.type}}</span>
                  </td>
                  <td>
                    <span class="badge badge-{{ item?.etat === 'INACTIF' ? 'danger': (item?.etat === 'AUCUN MANDAT' ? 'warning' : 'success') }}">
                      {{ item?.etat }}
                    </span>
                  </td>
                  <td class="align-middle">
                    <span class="text-danger">{{item?.impaye|number}} {{global.device}}</span>
                  </td>
                  <td class="align-middle">
                    <p class="m-0 d-inline-block align-middle font-16">
                      <span class="text-warning">{{item?.reverse|number}} {{global.device}}</span>
                    </p>
                  </td>
                  <td class="table-action">
                    <button *ngxPermissionsOnly="'OWNER:SHOW'" (click)="showOwner(item)" type="button" class="btn btn-icon btn-secondary"
                      ngbTooltip="Détails">
                      <i class="fas fa-eye"></i>
                    </button>
                    <button *ngxPermissionsOnly="'OWNER:EDIT'" (click)="editOwner(item)" type="button" class="btn btn-icon btn-primary ml-1"
                      ngbTooltip="Modifier">
                      <i class="feather icon-edit-2"></i>
                    </button>
                    <button *ngxPermissionsOnly="'OWNER:PRINTER:SHOW'" (click)="printerOwner(item)" type="button" class="btn btn-icon btn-warning ml-1"
                      ngbTooltip="Imprimer">
                      <i class="feather icon-printer"></i>
                    </button>
                    <ng-template ngxPermissionsOnly="OWNER:DELETE">
                      <button *ngIf="item?.etat === 'DISPONIBLE'" type="button" (click)="deleteOwner(item)" class="btn btn-icon btn-danger ml-1" ngbTooltip="Supprimer">
                        <i class="feather icon-trash"></i>
                      </button>
                    </ng-template>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="4" class="font-weight-bold font-size-40 text-right">TOTAL</td>
                  <td class="font-weight-bold font-size-40 text-warning"> {{totalImpaye | number}} {{global.device}}</td>
                  <td class="font-weight-bold font-size-40 text-success"> {{totalReverse | number}} {{global.device}}</td>
                  <td></td>
                  <td></td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
  <ng-template ngxPermissionsExcept="OWNER:LIST" [ngxPermissionsOnly]="['HOUSE:LIST', 'RENTAL:LIST', 'MANDATE:LIST', 'PAYMENT:REPAYMENT:LIST',
    'REPAYMENT:LIST', 'RENEW:MANDATE:LIST']">
    <div class="col-md-12">
      <app-no-droit *ngIf="type === 'PROPRIETAIRE'" [title]="'liste propriétaire'"></app-no-droit>
    </div>
  </ng-template>

  <!-- LISTE DES BIENS -->
  <ng-template ngxPermissionsOnly="HOUSE:LIST">
    <div class="col-md-12" *ngIf="type === 'BIEN' && houses && houses.length === 0">
      <span class="badge badge-secondary mb-4 f-14 width">
        LISTE DES BIENS
      </span>
      <app-no-data [title]="'Aucun bien trouvé'" *ngIf="type === 'BIEN' && houses && houses.length === 0"></app-no-data>
    </div>
    <app-house-list [houses]="houses" *ngIf="type === 'BIEN' && houses && houses.length > 0" class="width">
    </app-house-list>
  </ng-template>
  <ng-template ngxPermissionsExcept="HOUSE:LIST">
    <div class="col-md-12">
      <app-no-droit *ngIf="type === 'BIEN'" [title]="'liste bien'"></app-no-droit>
    </div>
  </ng-template>

  <!-- LISTE DES LOCATIVES -->
  <ng-template ngxPermissionsOnly="RENTAL:LIST">
    <div class="col-md-12" *ngIf="type === 'LOCATIVE'">
      <span class="badge badge-info mb-4 f-14 width">
        LISTE DES LOCATIVES
      </span>
      <app-card [hidHeader]="true" cardClass="card-datatable" *ngIf="rentals && rentals.length > 0">
        <div class="row">
          <app-rental-list [rentals]="rentals" [action]="true" class="width"></app-rental-list>
        </div>
      </app-card>
      <app-no-data [title]="'Aucune locative trouvée'" *ngIf="rentals && rentals.length === 0"></app-no-data>
    </div>
  </ng-template>
  <ng-template ngxPermissionsExcept="RENTAL:LIST">
    <div class="col-md-12">
      <app-no-droit *ngIf="type === 'LOCATIVE'" [title]="'liste locative'"></app-no-droit>
    </div>
  </ng-template>

    <!-- LISTE DES MANDATS -->
    <div  class="width list-mandat">
      <ng-template [ngxPermissionsOnly]="['MANDATE:TRUSTEE:LIST']">
        <div class="col-md-12" *ngIf="type === 'MANDAT'">
            <span class="badge badge-warning mb-4 f-14 width">
                LISTE DES MANDATS
            </span>
            <div>
                <app-no-data [title]="'Aucun mandat trouvé'" *ngIf="mandats && mandats.length === 0"></app-no-data>
                <app-card *ngIf="mandats && mandats.length > 0" [hidHeader]="true" cardClass="card-datatable">
                    <app-syndic-mandate-list [mandats]="mandats"></app-syndic-mandate-list>
                </app-card>
            </div>
        </div>
      </ng-template>
    </div>
    <ng-template ngxPermissionsExcept="MANDATE:TRUSTEE:LIST" [ngxPermissionsOnly]="['MANDATE:TRUSTEE:LIST']">
      <div class="col-md-12">
        <app-no-droit *ngIf="type === 'MANDAT'" [title]="'liste des mandats syndics'"></app-no-droit>
      </div>
    </ng-template>
    <!-- LISTE DES MANDATS -->

    <!-- LISTE DE LOTS  -->
    <div class="width list-lot">
        <div *ngIf="type === 'LOT'" class="col-md-12">
            <span class="badge badge-primary mb-4 f-14 width">
                LISTE DES LOTS
            </span>
            <ng-template [ngxPermissionsOnly]="['HOMECO:LIST']">
              <app-no-data [title]="'Aucun lot trouvé'" *ngIf="coproprietes && coproprietes.length === 0"></app-no-data>
              <div *ngIf="coproprietes && coproprietes.length > 0" class="row">
                  <div class="col-lg-4 col-md-4" *ngFor="let item of coproprietes">
                      <app-card [customHeader]="true" headerClass="border-0 p-2 pb-0" [options]="false" cardClass="user-card user-card-1" blockClass="pt-0 users-contact">
                          <div class="app-card-header" (click)="showHouse(item)">
                              <div class="row align-items-end">
                                  <div *ngIf="item?.type !== 'VERTICAL'" class="col text-left pb-3">
                                    <span>{{item?.owner?.nom}}</span> <br />
                                  </div>
                                  <div class="col text-right pb-3">
                                    <span class="badge badge-light-warning ml-1">
                                      <span *ngIf="item?.categorie">
                                        {{item?.categorie === 'APPARTEMENT' ? 'Appartement ' : item?.categorie === 'BUREAU' ? 'Bureau' : item?.categorie === 'MAGASIN' ? 'Magasin' : item?.categorie === 'SURFACE' ? 'Surface' : item?.categorie === 'RESTAURANT' ? 'Restaurant' : item?.categorie === 'HALL' ? 'Hall' : item?.categorie === 'SALLE CONFERENCE' ? 'Salle de conférence' : item?.categorie === 'PARKING' ? 'Parking' : item?.categorie === 'VILLA' ? 'Villa' : item?.categorie === 'STUDIO' ? 'Studio' : ''}}
                                      </span>
                                      <span *ngIf="item?.type === 'VERTICAL'">
                                        Vertical
                                      </span>
                                      <span *ngIf="item?.type === 'HORIZONTAL' && item?.categorie === 'HORIZONTAL'">Horizontal</span>
                                    </span>
                                  </div>
                              </div>
                            <div class="cover-img-block condominium-pic">
                              <img [src]="item?.photoSrc ? publicUrl+'/'+item.photoSrc : 'assets/images/house-default.png'" alt="{{ item?.nom }}"
                              title="{{ item?.nom }}" class="img-fluid">
                            </div>
                          </div>
                          <div class="text-center" (click)="showHouse(item)">
                            <div class="row align-items-end">
                            </div>
                            <div class="alert-info mb-1">
                              <div class="col-md-12 mt-2 text-center p-2">
                                <h5 class="bold">
                                  Lot : {{item?.nom}}
                                </h5>
                              </div>
                            </div>
                            <h6 class="mb-1 mt-3">
                              Syndic : {{item?.trustee?.nom}}
                            </h6>
                            <h6 class="mb-1 mt-3">
                              Copropriétaire : {{item?.ownerCo?.nom}} 
                            </h6>
                            <h6 class="mb-1 mt-3">
                              <span>
                                Superficie : {{item?.superficie}} (m²)
                              </span>
                              <span *ngIf="item?.type !== 'VERTICAL'">
                                - Numéro de porte : {{item?.numPorte}}
                              </span>
                            </h6>
                            <hr *ngIf="item?.tantiemes">
                            <h5 *ngIf="item?.tantiemes">Tantième(s)</h5>
                            <h6 *ngFor="let tantieme of item?.tantiemes">
                              <span>{{tantieme?.type}} - </span>
                              <span> valeur : {{tantieme?.valeur | number}} </span>
                            </h6>
                          </div>
                          <hr>
                          <div class="row mb-2" (click)="showHouse(item)">
                            <div class="col text-left">
                              <p class="mb-0">Code : {{item?.code}}</p>
                            </div>
                          </div>
                          <div class="row align-content">
                            <div class="col text-right">
                              <ng-template [ngxPermissionsOnly]="['HOMECO:SHOW']">
                                <button (click)="showHouse(item)" type="button" class="btn btn-icon btn-secondary" ngbTooltip="Détails">
                                  <i class="fas fa-eye"></i>
                                </button>
                              </ng-template>
                              <ng-template [ngxPermissionsOnly]="['HOMECO:EDIT']">
                                <button (click)="editHouse(item)" type="button" class="btn btn-icon btn-primary ml-1" ngbTooltip="Modifier">
                                  <i class="feather icon-edit-2"></i>
                                </button>
                              </ng-template>
                              <ng-template [ngxPermissionsOnly]="['HOMECO:PRINTER:SHOW']">
                                <button (click)="printHouse(item)" type="button" class="btn btn-icon btn-warning ml-1" ngbTooltip="Imprimer">
                                  <i class="feather icon-printer"></i>
                                </button>
                              </ng-template>
                              <ng-template [ngxPermissionsOnly]="['HOMECO:DELETE']">
                                <button (click)="delete(item)" type="button" class="btn btn-icon btn-danger ml-1" ngbTooltip="Supprimer">
                                  <i class="feather icon-trash"></i>
                                </button>
                              </ng-template>
                       
                            </div>
                          </div>
                      </app-card>
                  </div>
                </div>
            </ng-template>
        </div>
        <ng-template ngxPermissionsExcept="HOMECO:LIST" [ngxPermissionsOnly]="['HOMECO:LIST']">
          <div class="col-md-12">
            <app-no-droit *ngIf="type === 'LOT'" [title]="'liste des lots'"></app-no-droit>
          </div>
        </ng-template>
    </div>
    <!-- LISTE DE LOTS  -->

    <!-- LISTE DES INFRASTRUCTURE -->
    <div class="width list-infrastructure">
      <ng-template [ngxPermissionsOnly]="['INFRASTRUCTURE:TRUSTEE:LIST']">
        <div *ngIf="type === 'INFRASTRUCTURE'" class="col-md-12">
            <span class="badge badge-dark mb-4 f-14 width">
                LISTE DES INFRASTRUCTURES
            </span>
            <div>
                <app-no-data [title]="'Aucune infrastructure trouvée'" *ngIf="infrastructures && infrastructures.length === 0"></app-no-data>
                <app-infrastructure-list [infrastructures]="infrastructures" *ngIf="infrastructures && infrastructures.length > 0"></app-infrastructure-list>
            </div>
        </div>
      </ng-template>
    </div>
    <ng-template ngxPermissionsExcept="INFRASTRUCTURE:TRUSTEE:LIST" [ngxPermissionsOnly]="['INFRASTRUCTURE:TRUSTEE:LIST']">
      <div class="col-md-12">
        <app-no-droit *ngIf="type === 'INFRASTRUCTURE'" [title]="'liste des infrastructures'"></app-no-droit>
      </div>
    </ng-template>
    <!-- LISTE DES INFRASTRUCTURE -->

      <!-- LISTE DES BUDGET -->
      <div class="width list-budget">
        <ng-template [ngxPermissionsOnly]="['BUDGET:LIST']">
          <div *ngIf="type === 'BUDGET'" class="col-md-12">
              <span class="badge badge-warning mb-4 f-14 width">
                  LISTE DES BUDGETS
              </span>
              <div>
                  <app-no-data [title]="'Aucune budget trouvé'" *ngIf="budgets && budgets.length === 0"></app-no-data>
                  <app-syndic-budget-list [budgets]="budgets" [type]="'BUDGET'" *ngIf="budgets && budgets.length > 0"></app-syndic-budget-list>
              </div>
          </div>
        </ng-template>
      </div>
      <ng-template ngxPermissionsExcept="BUDGET:LIST" [ngxPermissionsOnly]="['BUDGET:LIST']">
        <div class="col-md-12">
          <app-no-droit *ngIf="type === 'BUDGET'" [title]="'liste des budgets'"></app-no-droit>
        </div>
      </ng-template>
      <!-- LISTE DES budgets -->

      <!-- LISTE DES TYPES DE CHARGE -->
      <!-- <ng-template ngxPermissionsOnly="TYPELOAD:LIST"> -->
      <div class="col-md-12" *ngIf="type === 'TYPE_LOAD'">
        <app-no-data [title]="'Aucun type de charge trouvé'" *ngIf="typeLoads.length === 0"></app-no-data>
      </div>
      <app-type-load-list class="width" [typeLoads]="typeLoads"
        *ngIf="type === 'TYPE_LOAD' && typeLoads && typeLoads.length > 0"></app-type-load-list>
      <!-- </ng-template> -->

</div>
