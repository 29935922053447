<div class="col-sm-12">
    <app-card [hidHeader]="true" cardClass="card-datatable">
      <div class="table-responsive">
        <table *ngIf="etat" datatable [dtOptions]="dtOptions" class="table table-sm table-striped table-bordered nowrap table-hover">
          <thead class="thead-light">
          <tr>
            <th *ngIf="owner">Propriétaire</th>
            <th >Libelle</th>
            <th>Trésorérie</th>
            <th>Etat</th>
            <th>Date</th>
            <th>Montant</th>
            <th *ngIf="action">Action</th>
          </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of deposits">
              <td *ngIf="owner">
                <p class="m-0 d-inline-block align-middle font-16">
                  <span class="text-warning">{{item?.owner?.searchableTitle}}</span> <br />
                  Type : {{item?.owner?.type}}<br />
                  Telephone : {{item?.owner?.telephone}}
                </p>
              </td>
              <td>{{item?.libelle}}</td>
              <td *ngIf="owner">
                <p class="m-0 d-inline-block align-middle font-16">
                  <span class="text-warning">{{item?.treasury?.searchableTitle}}</span> <br />
                  Type : {{item?.treasury?.type}}<br />
                </p>
              </td>
              <td>
                <span class="badge" [ngClass]="{
                'badge-warning' : item?.etat === 'ATTENTE',
                'badge-success' : item?.etat === 'VALIDE',
                'badge-danger' : item?.etat === 'INVALIDE'
                }">{{validation(item?.etat)}}</span>
              </td>
              <td>{{item?.date|date: "d MMMM y" : '' : 'fr-FR'}}</td>
              <td><p class="m-0 d-inline-block align-middle font-16"><span class="text-warning">{{item?.montant|number}} {{global.device}}</span> <br /></p></td>
              <td class="table-action">
                <div class="overlay-edit">
                    <button (click)="show(item)" type="button" class="btn btn-icon btn-secondary ml-1"
                      ngbTooltip="Détails">
                      <i class="fas fa-eye"></i>
                    </button>
                    <button *ngIf="item?.etat === 'ATTENTE'" (click)="edit(item)" type="button" class="btn btn-icon btn-primary ml-1"
                      ngbTooltip="Modifier">
                      <i class="feather icon-edit-2"></i>
                    </button>
                    <button *ngIf="item?.etat === 'ATTENTE'" (click)="validate(item)" type="button" class="btn btn-icon  btn-success ml-1" ngbTooltip="Valider">
                      <i class="fas fa-check"></i>
                    </button>
                    <button *ngIf="item?.etat === 'VALIDE'" (click)="printer(item)" type="button" class="btn btn-icon btn-warning ml-1"
                      ngbTooltip="Imprimer">
                      <i class="feather icon-printer"></i>
                    </button>
                    <button *ngIf="item?.etat === 'ATTENTE'" (click)="delete(item)" type="button" class="btn btn-icon  btn-danger ml-1" ngbTooltip="Supprimer">
                      <i class="feather icon-trash"></i>
                    </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </app-card>
  </div>
