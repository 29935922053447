<div class="modal-header">
  <h5 class="modal-title">{{title}}</h5>
  <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
    (click)="modal.close('ferme')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="modal-body">
    <div class="col-md-12">
      <div class="form-group">
        <div class="row mb-2">
          <div class="flex-grow-0" style="min-width: 100%">
            <app-image-uploader (filesd)="loadfile($event)" [maxSize]="4" (imageUploaded)="setParam('photoUuid',$event)" [photo]="user ? user?.photo : null"></app-image-uploader>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="modal.close('ferme')" type="button" class="btn btn-secondary text-left" data-dismiss="modal">
      Fermer <i class="feather icon-x-circle"></i>
    </button>
    <button [disabled]="form.invalid" type="submit" class="btn btn-primary">
      Enregistrer <i class="feather icon-save"></i>
    </button>
  </div>
</form>
