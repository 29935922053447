<div class="user-about-block align-center row">
  <div class="col-md-2"></div>
  <div class="col-md-8 mt-4">
    <app-alert type="secondary">
      <div class="text-center item-image-box col-lg-12">
        <div class="item-image ml-2">
          <i class="fas fa-frown" id="image-oupps"></i>
        </div>
      </div>
      <div class="text-center item-info col-lg-12">
        <div class="ad-info">
          <h1>Oupss!!</h1>
          <h3>{{ title }}</h3>
        </div>
      </div>
    </app-alert>
  </div>
  <div class="col-md-2"></div>
</div>
