import { Quote } from '@model/quote';
import { Funding } from '@model/funding';
import { Globals } from '@theme/utils/globals';
import { Production } from '@model/production';
import { Component, OnInit } from '@angular/core';
import { Construction } from '@model/construction';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PaymentFunding } from '@model/payment-funding';
import { ActivatedRoute, Router } from '@angular/router';
import { QuoteService } from '@service/quote/quote.service';
import { FilterService } from '@service/filter/filter.service';
import { FundingService } from '@service/funding/funding.service';
import { DateHelperService } from '@theme/utils/date-helper.service';
import { ProductionService } from '@service/production/production.service';
import { ConstructionService } from '@service/construction/construction.service';
import { PaymentFundingService } from '@service/payment-funding/payment-funding.service';
import { ConstructionAddComponent } from '@chantier/construction/construction-add/construction-add.component';
import { NgxPermissionsService } from 'ngx-permissions';
import { InvoiceCo } from '@model/prestataire/invoice-co';
import { InvoiceService } from '@service/invoice/invoice.service';
import { InvoiceCoService } from '@service/invoice-co/invoice-co.service';
import { QuoteAddComponent } from '@agence/chantier/quote/quote-add/quote-add.component';
import { InvoiceCoAddComponent } from '@agence/chantier/invoice-co/invoice-co-add/invoice-co-add.component';
import { FundingAddComponent } from '@agence/chantier/funding/funding-add/funding-add.component';
import { ProductionAddComponent } from '@agence/chantier/production/production-add/production-add.component';
import { Location } from '@angular/common';
import { Ticket } from '@model/ticket';

@Component({
  selector: 'app-construction-show',
  templateUrl: './construction-show.component.html',
  styleUrls: ['./construction-show.component.scss']
})
export class ConstructionShowComponent implements OnInit {
  public activeTab: string = 'INTERVENTION';
  construction: Construction;
  ticket: Ticket;
  constructionVal: boolean = false;
  quotes: Quote[];
  bons: Quote[];
  invoices: InvoiceCo[];
  productions: Production[];
  fundings: Funding[];
  payments: PaymentFunding[];
  type: string = 'INTERVENTION';
  name: boolean = true;
  global = { country: Globals.country, device: Globals.device }
  userSession = Globals.user;
  modelRef: NgbModalRef
  etatRow = [
    { label: 'DISPONIBLE', value: 'DISPONIBLE' },
    { label: 'VENDU', value: 'VENDU' }
  ];
  typeRow = [
    { label: 'INTERVENTION', value: 'INTERVENTION' },
    { label: 'DEVIS', value: 'DEVIS' },
    { label: 'BON DE COMMANDE', value: 'BON' },
    { label: 'FINANCEMENT', value: 'FINANCEMENT' },
    { label: 'REALISATION', value: 'REALISATION' },
    { label: 'PAIEMENT', value: 'PAIEMENT' }
  ];
  categorieRow = [
    { label: 'EN LOCATION', value: 'LOCATION' },
    { label: 'EN VENTE', value: 'VENTE' }
  ];
  nameTitle: string = "Nom du bien"
  userTitle: string = "Crée par"
  minTitle: string = "Montant MIN"
  maxTitle: string = "Montant MAX"
  categorieTitle: string = "Type de bien"
  etatTitle: string = "Disponibilité ?"




  constructor(
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private quoteService: QuoteService,
    private filterService: FilterService,
    private fundingService: FundingService,
    private invoiceService: InvoiceCoService,
    private productionService: ProductionService,
    private paymentService: PaymentFundingService,
    private constructionService: ConstructionService,
    private permissionsService: NgxPermissionsService
  ) {
    this.construction = this.constructionService.getConstruction(),
    this.ticket = this.constructionService.getConstruction().ticket
    this.onChangeLoad(this.type);
    const permission = JSON.parse(localStorage.getItem('permission-zen')) ? JSON.parse(localStorage.getItem('permission-zen')) : [];
    this.permissionsService.loadPermissions(permission);
  }

  ngOnInit(): void {
  }

  onFilter($event) {
    this.quotes = []
    this.productions = []
    this.fundings = []
    this.payments = []
    $event.type = this.activeTab
    this.filterService.search($event, 'construction', this.construction.uuid).subscribe(
      res => {
        if (this.activeTab === 'DEVIS') {
          return this.quotes = res;
        } if (this.activeTab === 'BON') {
          return this.bons = res;
        } if (this.activeTab === 'FACTURE') {
          return this.invoices = res;
        } else if (this.activeTab === 'FINANCEMENT') {
          return this.fundings = res;
        } else if (this.activeTab === 'REALISATION') {
          return this.productions = res;
        } else if (this.activeTab === 'PAIEMENT') {
          return this.payments = res;
        }
      }, err => { })
  }
  onChangeLoad(type): void {
    this.activeTab = type;
    if (type === 'INTERVENTION') {
      if (!this.construction) {
        this.constructionService.getSingle(this.route.snapshot.params.id).subscribe((res: any) => {
          return this.construction = res?.data;
        });
      }
    } else if (type === 'DEVIS') {
      this.typeRow = [{ label: 'DEVIS', value: 'DEVIS' }];
      this.name = false;
      this.nameTitle = 'Libellé'
      this.etatTitle = 'Etat'
      this.categorieTitle = 'Type'
      this.etatRow = [
        { label: 'VALIDE', value: 'VALIDE' },
        { label: 'INVALIDE', value: 'INVALIDE' }
      ];
      this.categorieRow = [
        { label: 'PRESTATION', value: 'PRESTATION' },
        { label: 'FOURNITURE', value: 'FOURNITURE' }
      ];
      this.quoteService.getList(this.construction.uuid, 'PRESTATION').subscribe((res) => {
        return this.quotes = res;
      }, error => { }
      );
    } else if (type === 'BON') {
      this.typeRow = [{ label: 'BON DE COMMANDE', value: 'BON' }];
      this.name = false;
      this.nameTitle = 'Libellé'
      this.etatTitle = 'Etat'
      this.categorieTitle = 'Type'
      this.etatRow = [
        { label: 'VALIDE', value: 'VALIDE' },
        { label: 'INVALIDE', value: 'INVALIDE' }
      ];
      this.categorieRow = [
        { label: 'FOURNITURE', value: 'FOURNITURE' }
      ];
      this.quoteService.getList(this.construction.uuid, 'PRESTATION',null, 1).subscribe((res) => {
        return this.bons = res;
      }, error => { }
      );
    } else if (type === 'FACTURE') {
      this.typeRow = [{ label: 'FACTURE', value: 'FACTURE' }];
      this.name = false;
      this.nameTitle = 'Libellé'
      this.etatTitle = 'Etat'
      this.categorieTitle = 'Type'
      this.etatRow = [
        { label: 'VALIDE', value: 'VALIDE' },
        { label: 'INVALIDE', value: 'INVALIDE' }
      ];
      this.categorieRow = [
        { label: 'FACTURE', value: 'FACTURE' }
      ];
      this.invoiceService.getList(this.construction.uuid, 'PRESTATION').subscribe((res) => {
        return this.invoices = res;
      }, error => { }
      );
    } else if (type === 'FINANCEMENT') {
      this.name = false;
      this.categorieTitle = 'Financeur'
      this.etatTitle = 'Etat'
      this.typeRow = [{ label: 'FINANCEMENT', value: 'FINANCEMENT' }];
      this.categorieRow = [
        { label: 'AGENCE', value: 'AGENCE' },
        { label: 'PROPRIETAIRE', value: 'PROPRIETAIRE' }
      ];
      this.etatRow = [
        { label: 'VALIDE', value: 'VALIDE' },
        { label: 'INVALIDE', value: 'INVALIDE' }
      ];
      this.fundingService.getList(this.construction.uuid).subscribe((res) => {
        return this.fundings = res;
      }, error => { }
      );
    } else if (type === 'REALISATION') {
      this.name = false
      this.typeRow = [{ label: 'REALISATION', value: 'REALISATION' }];
      this.categorieRow = []
      this.productionService.getList(this.construction.uuid).subscribe((res) => {
        return this.productions = res;
      }, error => { }
      );
      this.etatTitle = 'Etat'
      this.etatRow = [
        { label: 'VALIDE', value: 'VALIDE' },
        { label: 'INVALIDE', value: 'INVALIDE' }
      ];
    } else if (type === 'PAIEMENT') {
      this.typeRow = [{ label: 'PAIEMENT', value: 'PAIEMENT' }];
      this.categorieRow = []
      this.paymentService.getList(null, this.construction.uuid).subscribe((res) => {
        return this.payments = res;
      }, error => { }
      );
    }else if (type === 'TICKET') {
      this.typeRow = [{ label: 'TICKET', value: 'TICKET' }];
      this.categorieRow = []
   
    }
  }
  openShareModal(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
  }
  editConstruction(row) {
    this.constructionService.setConstruction(row);
    this.constructionService.edit = true;
    this.constructionService.type = row.type;
    this.modal(ConstructionAddComponent, 'modal-basic-title', 'xl', true, 'static');
  }

  addQuote(type) {
    this.modalService.dismissAll();
    this.quoteService.edit = false;
    this.modal(QuoteAddComponent, 'modal-basic-title', 'xl', true, 'static');
    this.modelRef.componentInstance.type = type ?? "LOCATIVE"
  }

  addBon(type) {
    this.modalService.dismissAll();
    this.quoteService.edit = false;
    this.modal(QuoteAddComponent, 'modal-basic-title', 'xl', true, 'static');
    this.modelRef.componentInstance.type = type ?? "LOCATIVE"
    this.modelRef.componentInstance.isBon = true
  }

  addInvoiceCo(type) {
    this.modalService.dismissAll();
    this.quoteService.edit = false;
    this.modal(InvoiceCoAddComponent, 'modal-basic-title', 'xl', true, 'static');
    this.modelRef.componentInstance.type = type ?? "LOCATIVE"
  }

  addProduction() {
    this.modalService.dismissAll();
    this.productionService.edit = false;
    this.modal(ProductionAddComponent, 'modal-basic-title', 'xl', true, 'static');
  }

  addFunding() {
    this.modalService.dismissAll();
    this.fundingService.edit = false;
    this.modal(FundingAddComponent, 'modal-basic-title', 'xl', true, 'static');
  }

  printerConstruction(row): void {
    this.constructionService.getPrinter('SHOW', this.userSession?.agencyKey, this.userSession?.uuid, row?.uuid);
  }
  back() { this.location.back(); }
  delete(item) {
    Swal.fire({
      title: '',
      text: 'Voulez-vous vraiment supprimer cet enrégistrement ?',
      icon: '',
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Supprimer <i class="feather icon-trash"></i>',
      confirmButtonColor: '#d33',
      timer: 2500,
      reverseButtons: true
    }).then((willDelete) => {
      if (willDelete.dismiss) { }
      else {
        this.constructionService.getDelete(item.uuid).subscribe((res: any) => {
          if (res?.status === 'success') { this.router.navigate(['/admin/intervention']) }
        });
      }
    });
  }
  modal(component, type, size, center, backdrop, inputs?) {
    this.modelRef = this.modalService.open(component, {
      ariaLabelledBy: type,
      size: size,
      centered: center,
      backdrop: backdrop
    })
  }
  formatDate(date): string {
    return DateHelperService.fromJsonDate(date);
  }

  timelapse(dateD, dateF): string {
    return DateHelperService.getTimeLapse(dateD, dateF, false, 'dmy');
  }

}
