<div class="auth-wrapper maintenance">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="text-center">
          <img src="assets/images/404.png" alt="" class="img-fluid">
          <h5 class="text-muted my-4">Oops ! Page non trouvée !</h5>
          <button (click)="back()" class="btn waves-effect waves-light btn-primary mb-4">
            <i class="fa fa-arrow-alt-circle-left"></i> Retour
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
