<div class="modal-header">
    <h5 class="modal-title">{{ title }}</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="onClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form autocomplete="off" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="modal-body">
      <div class="form-row">       
          <div class="col-md">
            <label for="libelle">Libellé <span class="asterix">*</span></label>
            <input
              type="text"
              formControlName="libelle"
              class="form-control"
              id="libelle"
              [ngClass]="{
                'is-invalid': submit && f.libelle.errors,
                'is-valid': submit && f.libelle.valid
              }"
              placeholder="Libellé"
            />
            <div class="invalid-feedback" *ngIf="submit && f.libelle.errors">
              <div *ngIf="f.libelle.errors.required">{{ required.novide }}</div>
            </div>
          </div>
      </div>
    </div>
    <hr class="m-0">
    <div class="modal-footer">     
        <button (click)="onClose()" type="button" class="btn btn-secondary" data-dismiss="modal">
          <i class="ion-md-close-circle"></i> Fermer
        </button>
        <button (click)="form.reset()" type="button" class="btn btn-warning mx-2">
          <i class="ion-md-brush"></i> Vider
        </button>
        <button [disabled]="form.invalid" type="submit" class="btn btn-primary">
          <i class="ion-md-save"></i> Enregistrer
        </button>
    </div>
  </form>
  