<div class="modal-header">
  <h5 class="modal-title">{{ title }}</h5>
  <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="onClose()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <span class="badge badge-warning mb-4 f-14 width">
      DETAIL SUR LE COPROPRIETAIRE
  </span>
  <div class="col-md-12">
      <div class="row">
          <div class="col-md-6">
              <div class="row">
                  <div class="col-md-4">
                      <span>Reférence :</span>
                  </div>
                  <div class="col-md-8">
                      <span class="bold">
                          {{ownerCo?.code}}
                      </span>
                  </div>
              </div>
              <hr class="my-2">
              <div class="row">
                  <div class="col-md-4">
                      <span>Type :</span>
                  </div>
                  <div class="col-md-8">
                    <span  class="bold">{{ownerCo?.type}}</span>
                  </div>
              </div>
              <hr class="my-2">
              <div class="row">
                  <div class="col-md-4">
                      <span>Nom :</span>
                  </div>
                  <div class="col-md-8">
                    <span *ngIf="ownerCo?.type === 'ENTREPRISE'" class="bold">{{ownerCo?.nom}}</span>
                    <span *ngIf="ownerCo?.type === 'PARTICULIER'" class="bold">{{ownerCo?.civilite}}  {{ownerCo?.nom}}</span>
                  </div>
              </div>
              <hr class="my-2">
              <div class="row">
                  <div class="col-md-4">
                      <span>Telephone :</span>
                  </div>
                  <div class="col-md-8">
                      <span class="bold">
                          {{ownerCo?.telephone }}
                      </span>
                  </div>
              </div>
              <hr class="my-2">
              <div class="row">
                  <div class="col-md-4">
                      <span>Email :</span>
                  </div>
                  <div class="col-md-8">
                      <span class="bold">
                          {{ownerCo?.email }}
                      </span>
                  </div>
              </div>
          </div>
          <div class="col-md-6">
                <div class="row">
                  <div class="col-md-4">
                      <span>
                          Date de création : <br />
                          Créer par
                      </span>
                  </div>
                  <div class="col-md-8">
                      <span class="bold">
                          {{ownerCo?.createdAt| date: "d MMMM y 'à' H:mm:ss " : '' : 'fr-FR'}}<br />
                          {{ownerCo?.create}}
                      </span>
                  </div>
              </div>
              <hr class="my-2">
              <div class="row">
                  <div class="col-md-4">
                      <span>
                          Date de modification : <br />
                          Modifier par
                      </span>
                  </div>
                  <div class="col-md-8">
                      <span class="bold">
                          {{ownerCo?.updatedAt| date: "d MMMM y 'à' H:mm:ss " : '' : 'fr-FR'}}<br />
                          {{ownerCo?.update}}
                      </span>
                  </div>
              </div>
              <hr class="my-2">
          </div>
      </div>
  </div>
</div>
<div class="modal-footer">
  <button (click)="onClose()" type="button" class="btn btn-secondary text-left" data-dismiss="modal">
      Fermer <i class="feather icon-x-circle"></i>
  </button>
  <button class="btn btn-warning">
      Imprimer <i class="feather icon-printer"></i>
  </button>
</div>
