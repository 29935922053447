<div class="modal-header">
  <h5 class="modal-title">{{title}}</h5>
  <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
    (click)="onClose()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form autocomplete="off" [formGroup]="form" (ngSubmit)="onConfirme()">
  <div class="modal-body">
    <div class="row">
      <div class="col-md-6">
        <label for="periodicite">Periodicité <span class="asterix">*</span></label>
        <select class="form-control" id="periodicite" formControlName="periodicite"
          [ngClass]="{ 'is-invalid': submit && f.periodicite.errors, 'is-valid': submit && f.periodicite.valid }">
          <option *ngFor="let item of periodiciteRow" [ngValue]="item.value">{{item.label}}</option>
        </select>
        <div class="invalid-feedback" *ngIf="submit && f.periodicite.errors">
          <div *ngIf="f.periodicite.errors.required">{{required.novide}}</div>
        </div>
      </div>
      <div class="col-md-6">
        <label for="mois">{{ moisTitle }} <span class="asterix">*</span></label>
        <input type="month" formControlName="mois" class="form-control" id="mois"
          [ngClass]="{'is-invalid': submit && f.mois.errors,'is-valid': submit && f.mois.valid}"
          [placeholder]="moisTitle">
        <div class="invalid-feedback" *ngIf="submit && f.mois.errors">
          <div *ngIf="f.mois.errors.required">{{required.novide}}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="onClose()" type="button" class="btn btn-secondary text-left" data-dismiss="modal">
      Fermer <i class="feather icon-x-circle"></i>
    </button>
    <button (click)="onReset()" type="button" class="btn btn-warning">
      Vider <i class="fas fa-broom"></i>
    </button>
    <button [disabled]="form.invalid" type="submit" class="btn btn-primary">
      Enregistrer <i class="feather icon-save"></i>
    </button>
  </div>
</form>
