import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-piece-list',
  templateUrl: './piece-list.component.html',
  styleUrls: ['./piece-list.component.scss']
})
export class PieceListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
