<div class="modal-header">
    <h5 class="modal-title">{{title}}</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="onClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form autocomplete="off" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="modal-body">
      <div class="row">
        <span class="badge badge-info my-2 f-14 width"> DETAILS SUR LA CATEGORIE </span>
      </div>
      <div class="form-group row">
        <div class="col-md-12">
          <label for="libelle">Libelle<span class="asterix">*</span></label>
          <app-entity-finder [class]="'Category'" [groups]="['category']" [isAutoCreate]="true" [disabled]="edit"
            [required]="true" [selected]="categorySelected" (uuid)="setCategoryUuid($event)"
            [placeholder]="'Selectionez une category'">
          </app-entity-finder>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-12">
            <div formArrayName="etapes">
              <!-- LABEL DU TABLEAU DES ETAPES -->
              <div class="form-group" *ngIf="etapes.length > 0">
                <div class="row">
                  <div class="col-md-10">
                    <label>ETAPES <span class="asterix">*</span></label>
                  </div>
                </div>
              </div>
              <div class="form-group" *ngFor="let opt of etapes.controls; let i=index" [formGroupName]="i">
                <div class="row">
                  <div class="col-md-10">
                    <app-entity-finder [class]="'EtapeTraitement'" [groups]="['etapeT']" [isAutoCreate]="true" 
                      [required]="true" [selected]="opt.get('selected')?.value" (uuid)="setEtapeUuid($event, opt)"
                      [placeholder]="'Selectionez une etape'">
                    </app-entity-finder>
                  </div>
                  <div class="col-md-1">
                    <button (click)="onDelete(i)" type="button" class="btn btn-icon btn-danger">
                      <i class="feather icon-trash"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-2 d-flex flex-row-reverse">
              <button (click)="onAdd()" type="button" class="btn btn-primary">
                Ajouter <i class="feather icon-plus"></i>
              </button>
            </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button (click)="modal.close('ferme')" type="button" class="btn btn-secondary text-left" data-dismiss="modal">
        Fermer <i class="feather icon-x-circle"></i>
      </button>
      <button (click)="form.reset()" type="button" class="btn btn-warning">
        Vider <i class="fas fa-broom"></i>
      </button>
      <button [disabled]="form.invalid" type="submit" class="btn btn-primary">
        Enregistrer <i class="feather icon-save"></i>
      </button>
    </div>
  </form>
  