<div class="modal-header">
  <h5 class="modal-title">{{title}}</h5>
  <button *ngIf="type !== 'first'" type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="modal.close('ferme')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form autocomplete="off" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="modal-body">
    <div class="col-md-12">
      <div class="row">
        <span class="badge badge-warning my-2 f-14 width"> DETAILS DU MOT DE PASSE</span>
      </div>
      <div class="form-group">
        <div class="row mb-2">
          <div *ngIf="type === 'first'" class="width f-14">
            <app-alert type="warning"  dismiss="true">
              <i class="fas fa-directions mr-2"></i> Vous serrez rediriger vers la page de connexion après modification.
            </app-alert>
          </div>
          <div class="col-md-4">
            <label for="actuel">Actuel mot de passe<span class="asterix">*</span></label>
            <div class="input-group mb-4">
              <input [type]="passwordA" formControlName="actuel" class="form-control" id="actuel"
                [ngClass]="{
                  'is-invalid': submit && f.actuel.errors,
                  'is-valid': submit && f.actuel.valid
                }" placeholder="Actuel mot de passe">
              <div class="input-group-append" (click)="onTogglePassword('now')">
                <span class="input-group-text"><span class="fas" [ngClass]="passwordA === 'password' ? 'fa-eye' : 'fa-eye-slash'"></span></span>
              </div>
              <div class="invalid-feedback" *ngIf="submit && f.actuel.errors">
                <div *ngIf="f.actuel.errors.required">{{required.novide}}</div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <label for="new">Nouveau mot de passe<span class="asterix">*</span></label>
            <div class="input-group mb-4">
              <input [type]="passwordN" formControlName="new" class="form-control" id="new"
                [ngClass]="{
                  'is-invalid': submit && f.new.errors,
                  'is-valid': submit && f.new.valid
                }" placeholder="Nouveau mot de passe">
                <div class="input-group-append" (click)="onTogglePassword('new')">
                  <span class="input-group-text"><span class="fas" [ngClass]="passwordN === 'password' ? 'fa-eye' : 'fa-eye-slash'"></span></span>
                </div>
              <div class="invalid-feedback" *ngIf="submit && f.new.errors">
                <div *ngIf="f.new.errors.required">{{required.novide}}</div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <label for="confirme">Confirmer le mot de passe<span class="asterix">*</span></label>
            <div class="input-group mb-4">
              <input [type]="passwordC" formControlName="confirme" class="form-control" id="confirme"
                [ngClass]="{
                  'is-invalid': submit && f.confirme.errors,
                  'is-valid': submit && f.confirme.valid
                }" placeholder="Confirmer le mot de passe">
                <div class="input-group-append" (click)="onTogglePassword('confirmate')">
                  <span class="input-group-text"><span class="fas" [ngClass]="passwordC === 'password' ? 'fa-eye' : 'fa-eye-slash'"></span></span>
                </div>
              <div class="invalid-feedback" *ngIf="submit && f.confirme.errors">
                <div *ngIf="f.confirme.errors.required">{{required.novide}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button *ngIf="type !== 'first'" (click)="modal.close('ferme')" type="button" class="btn btn-secondary text-left" data-dismiss="modal">
      Fermer <i class="feather icon-x-circle"></i>
    </button>
    <button (click)="form.reset()" type="button" class="btn btn-warning">
      Vider <i class="fas fa-broom"></i>
    </button>
    <button [disabled]="form.invalid" type="submit" class="btn btn-primary">
      Enregistrer <i class="feather icon-save"></i>
    </button>
  </div>
</form>
