
  <div class="table-responsive">
    <table  datatable [dtOptions]="dtOptions"
      class="table table-sm table-striped table-bordered nowrap table-hover">
      <thead class="thead-light">
        <tr>
          <th>Reference</th>
          <th>Objet</th>
          <th>Catégorie </th>
          <th>Etat</th>
          <th>Priorité</th>
          <th>qualification</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of tickets">
          <td class="align-middle">
            <p class="m-0 d-inline-block align-middle font-16">
              <span class="text-primary">Ticket N° {{ item?.code }}</span> <br />
              Plaignant : {{ item?.libelle }}<br />
            </p>
          </td>
          <td class="align-middle">
            <p class="m-0 d-inline-block align-middle font-16">
              {{ item?.objet }}
            </p>
          </td>
          <td class="align-middle">
            <p class="m-0 d-inline-block align-middle font-16">
              {{ item?.category?.libelle }}
            </p>
          </td>
          <td class="align-middle">
            <span class="badge badge-{{item?.etat == 'OUVER' ? 'danger' : item?.etat == 'EN COURS' ? 'warning' : 'success'}}"> {{ item?.etat }}</span>
          </td>
          <td class="align-middle">
            <span class="badge badge-{{item?.urgence == 'URGENT' ? 'danger' : item?.urgence == 'IMPORTANT' ? 'danger' : 'warning'}}"> 
              {{ item?.urgence }}
            </span>
          </td>
          <td class="align-middle">
            <p class="m-0 d-inline-block align-middle font-16">
              <span class="badge badge-warning" *ngIf="item?.qualifier === 'NON'">EN ATTENTE DE QUALIFICATION</span> <br />
              <span class="badge badge-success" *ngIf="item?.qualifier === 'OUI'">QUALIFIER</span> <br />
              <span   *ngIf="item?.qualifier === 'OUI'" >Le :  {{item?.qualifiedAt|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}</span> <br />
              <span   *ngIf="item?.qualifier === 'OUI'">Par :  {{item?.qualifieBy?.nom}} {{item?.qualifieBy?.prenom}}</span> <br />
            </p>
          </td>
          <td  class="table-action">
            <button (click)="show(item)" type="button" class="btn btn-icon btn-secondary ml-1"
              ngbTooltip="Détails">
              <i class="fas fa-eye"></i>
            </button>
            <button *ngIf="item?.qualifier === 'NON'"  (click)="edit(item)" type="button" class="btn btn-icon btn-primary ml-1" ngbTooltip="Modifier">
              <i class="feather icon-edit-2"></i>
            </button>
            <button *ngIf="item?.qualifier === 'NON'" (click)=" qualifier(item)" type="button" class="btn btn-icon btn-success ml-1" ngbTooltip="qualifier">
            </button>
            <button  *ngIf="item?.etat != 'FERME'"  (click)="ferme(item)" type="button" class="btn btn-icon btn-danger ml-1" ngbTooltip="Cloturer">
              <i class="fas fa-check-circle"></i>
            </button>
            <button  *ngIf="item?.qualifier === 'OUI' && item?.etat != 'FERME' "  (click)="note(item)" type="button" class="btn btn-icon btn-secondary ml-1" ngbTooltip="Note">
              <i class="fas fa-book"></i>
            </button>
 
          </td>
        </tr>
      </tbody>
    
    </table>
  </div>
  
  
  
  
  