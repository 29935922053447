<div class="row">
    <div class="col-md-3">
    <div class="card mb-3">
        <div class="card-header">
        <h4>Configuration</h4>
        </div>
        <div class="card-body p-3">
        <div class="cat-list">
            <div class="border-bottom pb-3 ">
            <a class="btn btn-light width" (click)="onDisplay('CONFIGURATION')">
                <i class="fa fa-user-check"></i> Confuguration générale
            </a>
            </div>
            <div class="border-bottom pb-3 ">
            <a class="btn btn-light width" (click)="onDisplay('CATEGORIE')">
                <i class="fa fa-cog"></i> Categorie de Ticket
            </a>
            </div>
            <div class="border-bottom pb-3 ">
            <a class="btn btn-light width" (click)="onDisplay('PROCEDURE')">
                <i class="fa fa-cog"></i> Procedure
            </a>
            </div>
        </div>
        </div>
    </div>
    </div>

    <div class="col-md-9">
    <!-- LISTE DES PARAMETRES GENERAUX -->

    <div class="card mb-3" *ngIf="type === 'CONFIGURATION'" >
    <div class="card-header d-flex">
        <div class="p-2">
        <h4><i class="fa fa-user-check"></i> Configuration générale</h4>
        </div>

    </div>
    <div class="card-body p-3">
        <form autocomplete="off" [formGroup]="form" (ngSubmit)="onSubmit()" >
            <div class="row mb-2">
            <div class="col-md-6">
                <label for="user">Gestionnaire des tickets</label>
                <ng-select [multiple]="true"  id="users" formControlName="users"  [(ngModel)]="chefSelected" >
                <ng-option *ngFor="let item of users" [value]="item?.uuid">{{item?.nom}}</ng-option>
                </ng-select>
            </div>
            <div class="col-md-12 text-right" >
                <button [disabled]="form.invalid" type="submit" class="btn btn-msg-send btn-primary ml-auto">
                    Enregistrer <i class="feather icon-save"></i>
                </button>
            </div>
            </div>

        </form>
        <div class="table-responsive"  *ngIf="gestionnaires && gestionnaires.length > 0">
            <table datatable [dtOptions]="dtOptions" class="table table-sm table-striped table-bordered nowrap table-hover">
              <thead class="thead-light">
                <tr>
                  <th>Nom & prénom</th>
                  <th>Crée le</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of gestionnaires">
                  <td class="align-middle">
                    <p class="m-0 d-inline-block align-middle font-16">
                      <span class="">{{ item?.user?.nom }}</span>
                    </p>
                  </td>
                  <td>
                    {{ item.createdAt| date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}
                    <p>
                      <span>Par: </span>
                      <span> {{item?.create}}</span>
                    </p>
    
                  </td>
                  <td class="table-action">
                    <button (click)="show(item)" type="button" class="btn btn-icon btn-secondary" ngbTooltip="Détails">
                      <i class="fas fa-eye"></i>
                    </button>
                    <button type="button" (click)="delete(item)" class="btn btn-icon btn-danger ml-1" ngbTooltip="Supprimer">
                      <i class="feather icon-trash"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
    </div>
    </div>

    <!-- LISTE DES ÉPATES DE PROSPECTION -->
    <app-category-list *ngIf="type === 'CATEGORIE'" class="width" [categories]="categories"></app-category-list>
    <app-procedure-list *ngIf="type === 'PROCEDURE'" class="width" [procedures]="procedures"></app-procedure-list>
  </div>
  