import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-outils',
  templateUrl: './outils.component.html',
  styleUrls: ['./outils.component.scss']
})
export class OutilsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
