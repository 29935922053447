import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-qualification-list',
  templateUrl: './qualification-list.component.html',
  styleUrls: ['./qualification-list.component.scss']
})
export class QualificationListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
