
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { EmitterService } from '@service/emitter/emitter.service';
import { OwnerCoService } from '@service/owner-co/owner-co.service';


@Component({
  selector: 'app-owner-co-show',
  templateUrl: './owner-co-show.component.html',
  styleUrls: ['./owner-co-show.component.scss']
})
export class OwnerCoShowComponent implements OnInit {

  ownerCo: any
  title: string = ''

  constructor(
    public modal: NgbActiveModal,
    private ownerCoServie : OwnerCoService,
    private emitter: EmitterService
  ) {
    this.ownerCo = this.ownerCoServie.getOwnerCo()
    const code = this.ownerCo ? this.ownerCo.code : ''
    this.title = 'Détail du copropriétaire ' + code
    console.log(this.ownerCo)
  }

  ngOnInit(): void {
  }

  onClose(){
    this.modal.close('ferme');
  }

  onValidate() {
    Swal.fire({
      title: '',
      text: 'Voulez-vous valider ce mandat ?',
      icon: 'warning',
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonText: 'Confirmer <i class="fas fa-check"></i>',
      cancelButtonText: 'Annuler <i class="feather icon-x-circle"></i>',
      confirmButtonColor: '#1bc943',
      reverseButtons: true
    }).then((willDelete) => {
      if (!willDelete.dismiss) {
        
      }
    });
  }

}
