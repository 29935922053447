<div class="col-md-12">
  <div *ngIf="coproprietes && coproprietes.length > 0" class="row">
    <div class="col-lg-4 col-md-4" *ngFor="let item of coproprietes">
      <app-card [customHeader]="true" headerClass="border-0 p-2 pb-0" [options]="false" cardClass="user-card user-card-1" blockClass="pt-0 users-contact">
        <div class="app-card-header" (click)="showHouse(item)">
          <div class="row align-items-end">
            <div *ngIf="item?.type !== 'VERTICAL'" class="col text-left pb-3">
              <span>{{item?.owner?.nom}}</span> <br />
            </div>
            <div class="col text-right pb-3">
              <span class="badge badge-light-warning ml-1">
                <span *ngIf="item?.categorie">
                  {{item?.categorie === 'APPARTEMENT' ? 'Appartement ' : item?.categorie === 'BUREAU' ? 'Bureau' : item?.categorie === 'MAGASIN' ? 'Magasin' : item?.categorie === 'SURFACE' ? 'Surface' : item?.categorie === 'RESTAURANT' ? 'Restaurant' : item?.categorie === 'HALL' ? 'Hall' : item?.categorie === 'SALLE CONFERENCE' ? 'Salle de conférence' : item?.categorie === 'PARKING' ? 'Parking' : item?.categorie === 'VILLA' ? 'Villa' : item?.categorie === 'STUDIO' ? 'Studio' : item?.categorie === 'PALIER' ? 'Palier' : ''}}
                </span>
                <span *ngIf="item?.type === 'VERTICAL'">
                  Vertical
                </span>
                <span *ngIf="item?.type === 'HORIZONTAL' && item?.categorie === 'HORIZONTAL'">Horizontal</span>
              </span>
            </div>
          </div>
          <div class="cover-img-block condominium-pic">
            <img [src]="item?.photoSrc ? publicUrl+'/'+item.photoSrc : 'assets/images/house-default.png'" alt="{{ item?.nom }}"
             title="{{ item?.nom }}" class="img-fluid">
          </div>
        </div>
        <div class="text-center" (click)="showHouse(item)">
          <div class="row align-items-end">
          </div>
          <div class="alert-info mb-1">
            <div class="col-md-12 mt-2 text-center p-2">
              <h5 class="bold">
                Lot : {{item?.nom}}
              </h5>
            </div>
          </div>
          <h6 class="mb-1 mt-3">
            Syndic : {{item?.trustee?.nom}}
          </h6>
          <h6 class="mb-1 mt-3">
            <span>
              Superficie : {{item?.superficie}} (m²)
            </span>
            <span *ngIf="item?.type !== 'VERTICAL'">
               - Numéro de porte : {{item?.numPorte}}
            </span>
          </h6>
          <hr *ngIf="item?.tantiemes">
          <h5 *ngIf="item?.tantiemes">Millième(s)</h5>
          <h6 *ngFor="let tantieme of item?.tantiemes">
            <span>{{tantieme?.type}} - </span>
            <span> valeur : {{tantieme?.valeur | number}} </span>
          </h6>
        </div>
        <hr>
        <div class="row mb-2" (click)="showHouse(item)">
          <div class="col text-left">
            <p class="mb-0">Code : {{item?.code}}</p>
          </div>
        </div>
        <div class="row align-content">
          <div class="col text-right">
              <button (click)="showHouse(item)" type="button" class="btn btn-icon btn-secondary" ngbTooltip="Détails">
                <i class="fas fa-eye"></i>
              </button>
              <button (click)="editHouse(item)" type="button" class="btn btn-icon btn-primary ml-1" ngbTooltip="Modifier">
                <i class="feather icon-edit-2"></i>
              </button>
              <button (click)="printHouse(item)" type="button" class="btn btn-icon btn-warning ml-1" ngbTooltip="Imprimer">
                <i class="feather icon-printer"></i>
              </button>
              <button (click)="delete(item)" type="button" class="btn btn-icon btn-danger ml-1" ngbTooltip="Supprimer">
                <i class="feather icon-trash"></i>
              </button>
          </div>
        </div>
      </app-card>
    </div>
  </div>
</div>
